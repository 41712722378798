import React from 'react';
import { graphql } from 'gatsby';
import Layout3 from '../components/layout2';
import Styles from "./mdlist.module.css";









const HomePage = (props: any) => {
  const { data } = props;


  return (
    <Layout3>
        <div style={{margin: "30px"}}>

          <h1>更新情報</h1>
          <p>ご確認いただき、ありがとうございます。</p>

          <h2>第18稿  - 1/15</h2><br/>        
          <h3>
            登録情報の更新
          </h3><p>
          　・現時点での情報を登録しました。<br/>
          　・<a href="/mdlist"><b>登録情報一覧</b></a>よりご確認ください。
          </p>
          <h3>
            kmlの増
          </h3><p>
          　・200番台のkmlを追加しました。14,18,106のkmlが時系列対応になりました。<br/>
          　・<a href="/mdlist"><b>登録情報一覧</b></a>よりご確認ください。
          </p>
          <h3>
            個別ページのデザイン更新
          </h3><p>
          　・メニューを仮設置しました（まだ機能しません。デザイン確認用です）。<br/>
          　・「典型的な都市」の紹介枠を設置しました。<br/>
          　・本文の修飾を試行しました。<br/>
          　　<a href="/105"><b>こちらなど各ページからご確認ください</b></a>
          
          </p>
          <h3>
            新Cesiumの検証
          </h3><p>
          　・先週公開された新Cesiumへの更新に向けて検証中<br/>
          　　<a href="/cesiumtest"><b>こちらからご確認ください</b></a>
          <br />
          </p>
          <h3>
            <font style={{color:"red"}}>テーマから選ぶ画面は作業中です</font>
          </h3><p>
          　・データがない項目を選択するとエラーが出ます。<br/>
          　・各ページへの移動には、<a href="/mdlist"><b>登録情報一覧</b></a>の各表左端のリンクをご利用ください。
          <br />
          </p>
          <h3>
            <font style={{color:"red"}}>都市を選び直した際には再読み込みが必要です</font>
          </h3><p>
          　・都市を選び直したあと、リロード（F5ボタン）でプレビュー画面を更新してください。
          <br />
          </p>


          <br/>
          <br/>


          <h2>第16稿  - 12/25</h2>    <br/>    
          <h3>
kmlデータとの接続（２都市比較）
          </h3><p>
　・２都市比較のページでも、データとの接続を行いました。kmlもダウンロードできるようになりました。
          </p>

          <br/>

          <h2>第15稿  - 12/24</h2>  <br/>      
          <h3>
            kmlデータとの接続
          </h3><p>
          　・データとの接続を行いました。kmlもダウンロードできるようになりました。<br />
          　・これにて、Ver3とVer6の参照は不要になります。（これまでお手数をおかけしました。）
          </p>
          <h3>
            凡例まわりのデザイン修正
          </h3><p>
          　・画像とテキストを組み合わせたデザインに修正しました。<br />
          　　<a href="/105"><b>こちらからご確認ください</b></a>
          <br />
          </p>
          <h3>
          「登録情報一覧」ページの更新</h3>
          <p>
          　・サイト内の情報を抽出し、見やすい表形式で整理しています。
          <br />
          　・データ更新方法についての解説をページ内に掲載しました。どなたでも修正や校正に関わっていただけます。
          <br />
          　　<a href="/mdlist"><b>「登録情報一覧」ページはこちらから</b></a>
          </p>


          <br/>
          <br/>


<img src="https://source.unsplash.com/featured/?mt.fuji" alt="" />



      </div>
    </Layout3>
  );
};

export default HomePage;

